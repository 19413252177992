/* TABLES */
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
	background-color: $main-bg-color;
}
.table {
	thead > tr {
		> th {
			border: 2px solid $main-bg-color !important;
			text-transform: uppercase;
			font-size: 0.875em;
            vertical-align: top;
			background-color: #e6e6e6 !important;
			white-space: nowrap;

			> a span {
				color: $text-color;
			}
		}
	}
	tbody > tr {
		> td {
			font-size: 0.875em;
			vertical-align: middle;
			border: 1px solid $main-bg-color;
			padding: 12px 8px;

			&:first-child {
				font-size: 1.125em;
				font-weight: 300;
			}
		}
		td {
			.call-type {
				display: block;
				font-size: 0.75em;
				text-align: center;
			}
			.first-line {
				line-height: 1.5;
				font-weight: 400;
				font-size: 1.125em;

				span {
					font-size: 0.875em;
					color: #969696;
					font-weight: 300;
				}
			}
			.second-line {
				font-size: 0.875em;
				line-height: 1.2;
			}
		}
		&.table-line-fb > td {
			background-color: #9daccb;
			color: #262525;
		}
		&.table-line-twitter > td {
			background-color: #9fccff;
			color: #262525;
		}
		&.table-line-plus > td {
			background-color: #eea59c;
			color: #262525;
		}
	}

	a {
		&.table-link {
			margin: 0 5px;
			font-size: 1.125em;

			&:hover {
				text-decoration: none;
				color: #2980b9;
			}
			&.danger {
				color: $red-color;

				&:hover {
					color: $red-color-dark;
				}
			}
		}
	}
}
.dataTable thead th:hover {
	color: $primary-color;
}
.table thead > tr > th > a span:after,
.dataTable .sorting:after,
.dataTable .sorting_asc:after,
.dataTable .sorting_desc:after {
	content: "\f0dc";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	margin-left: 5px;
	font-size: 0.75em;
}
.table thead > tr > th > a:hover {
	text-decoration: none;
}
.table thead > tr > th > a.asc span:after,
.dataTable .sorting_desc:after {
	content: "\f0dd";
}
.table thead > tr > th > a.desc span:after,
.dataTable .sorting_asc:after {
	content: "\f0de";
}
.table thead > tr > th > a:hover span {
	text-decoration: none;
	color: $primary-color;
	border-color: $primary-color;
}
.table.table-hover tbody > tr > td {
	@include transition(background-color 0.15s ease-in-out 0s);
}

.table-products {
	tbody > tr {
		> td {
			background: none;
			border: none;
			border-bottom: 1px solid #ebebeb;
			@include transition(background-color 0.15s ease-in-out 0s);
			position: relative;
		}
		&:hover > td {
			text-decoration: none;
			background-color: #f6f6f6;
		}
	}
	.name {
		display: block;
		font-weight: 600;
		padding-bottom: 7px;
	}
	.price {
		display: block;
		text-decoration: none;
		width: 50%;
		float: left;
		font-size: 0.875em;

		> i {
			color: $green-color;
		}
	}
	.warranty {
		display: block;
		text-decoration: none;
		width: 50%;
		float: left;
		font-size: 0.875em;

		> i {
			color: $yellow-color;
		}
	}
}
.table-stats {
	.status-social-icon {
		font-size: 1.9em;
		vertical-align: bottom;
	}
	.table-line-fb .status-social-icon {
		color: #556484;
	}
	.table-line-twitter .status-social-icon {
		color: #5885b8;
	}
	.table-line-plus .status-social-icon {
		color: #a75d54;
	}
}
.dataTables_length {
	margin-top: 10px;
	margin-bottom: 15px;
	margin-right: 30px;
	float: left;

	@media (max-width: $break-xs-max) {
		display: none;
	}
}
.dataTables_filter {
	float: right;
	margin-top: 10px;
	margin-bottom: 15px;

	@media (max-width: $break-xs-max) {
		display: none;
	}

	input {
		margin-left: 10px;
	}
}
.DTTT {
	@media (max-width: $break-xs-max) {
		display: none;
	}

	&.btn-group {
		float: right;
		margin-left: 10px;
		margin-top: 10px;
	}
	&.btn-group a.btn {
		height: 30px;
		line-height: 16px;
	}
}

/* USER LIST TABLE */
.user-list {
	tbody td {
		> img {
			position: relative;
			max-width: 50px;
			float: left;
			margin-right: 15px;
		}
		.user-link {
			display: block;
			font-size: 1.25em;
			padding-top: 3px;
			margin-left: 60px;
		}
		.user-subhead {
			font-size: 0.875em;
			font-style: italic;
		}
	}
}