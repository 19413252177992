.theme-somlivre {
	#header-navbar {
		background-color: $primary-color;
	}
	.navbar > .container .navbar-brand {
		background-color: $primary-color;
	}
	#nav-col,
	#page-wrapper {
		background-color: #4c5763;
	}
	#sidebar-nav .nav > .open > .submenu > li > .submenu,
	#sidebar-nav .nav > .active > .submenu > li > .submenu {
	    background-color: #2a3139;
	}
	#sidebar-nav .nav > .open > .submenu > .open > a,
	#sidebar-nav .nav > .active > .submenu > .open > a,
	#sidebar-nav .nav > .active > .submenu > .active > a {
		border-bottom-color: transparent;
	    box-shadow: 0 -1px 0 transparent inset;
	}
	#sidebar-nav .nav > .open > .submenu > li > .submenu > li a:hover,
	#sidebar-nav .nav > .active > .submenu > li > .submenu > li a:hover,
	#sidebar-nav .nav > .active > .submenu > li > .submenu > li a.active {
		color: $primary-color;
	}
	.nav-pills > li.active > a,
	.nav-pills > li.active > a:hover,
	.nav-pills > li.active > a:focus,
	#sidebar-nav .nav-pills > li.active > a,
	#sidebar-nav .nav-pills > li.active > a:hover,
	#sidebar-nav .nav-pills > li.active > a:focus,
	.nav-small #nav-col #sidebar-nav .nav-pills > li.open > a {
		background-color: #353d47;
	}
	#sidebar-nav .nav > li > a:hover {
		background-color: #353d47;
	}
	#header-navbar .nav > li > a {
		color: #fff;
	}
	#header-navbar .nav > li > a:hover,
	#header-navbar .nav > li > a:focus,
	#header-navbar .nav .open > a,
	#header-navbar .nav .open > a:hover,
	#header-navbar .nav .open > a:focus {
		background-color: #2980b9;
	}
	#sidebar-nav .nav li .submenu {
		background-color: #353d47;
	}
	#sidebar-nav .nav li .submenu > li > a:hover,
	#sidebar-nav .nav li .submenu > li > a.active,
	#sidebar-nav .nav li .submenu > li.active > a {
		background-color: #2a3139;
	}
	.navbar > .container .navbar-brand {
		color: #fff;
	}
	.navbar-toggle {
		color: #fff;
	}
	.pace .pace-progress {
		background-color: #fff;
	}
}
@media (max-width: $break-sm-max) {
	.theme-somlivre {
		#logo.navbar-brand > img.normal-logo.logo-white {
			display: block;
		}
		#logo.navbar-brand > img.normal-logo.logo-black {
			display: none;
		}
	}
}