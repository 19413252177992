/* INFOGRAPHIC BOX */
.infographic-box {
	padding: 20px;

	i {
		font-size: 2.4em;
		display: block;
		float: left;
		margin-right: 15px;
		width: 60px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		@include border-radius(50%);
		color: #fff;
	}
	.headline {
		display: block;
		font-size: 1.2em;
		font-weight: 300;
		text-align: right;
	}
	.value {
		font-size: 2.1em;
		font-weight: 600;
		margin-top: -5px;
		display: block;
		text-align: right;
	}
	&.merged {
		border-top: 1px solid $main-bg-color;
		border-right: 1px solid $main-bg-color;
		width: 50%;

		@media (max-width: $break-xs-max) {
			width: 100%;
			border-right: none;
		}
	
		.headline {
			text-align: left;
			font-size: 1em;
			margin-top: -5px;
		}
		.value {
			text-align: left;
			font-size: 1.9em;
			font-weight: 400;
			margin-top: 0;
		}
		&.merged-top {
			border-top: none;
		}
		&.merged-right {
			border-right: none;
		}
		&.merged-top.merged-right {
			@media (max-width: $break-xs-max) {
				border-top: 1px solid $main-bg-color;
			}
		}
	}
}