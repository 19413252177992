// Forms ======================================================================

form .form-group {
  padding-bottom:4px;
}

.tab-content {
  padding: 25px;
  margin-bottom: 25px;
}

.tab-content.tab-bordered {
  border: 1px solid #ddd;
  border-top: none;
}

.panel.panel-tabs .panel-body .nav-tabs li:first-child {
  padding-left: 15px;
}

// Grid Header Title
.main-box .main-box-header {
  padding: 10px 0px !important;
}

// Export Buttons Grid Header
.no-border-radius{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.border-radius {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.navbar {
  margin-bottom: 0px !important;
}

// Alerts ======================================================================

.alert {
  margin-bottom: 7px !important;
}

// Selectize ======================================================================

.selectize-input {
  border: none;
}

// Left Tabs
.tabs-left{
  border-top: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;

  li > a{
    color: #344644;
  }
  li.active > a{
    color: #ffffff;
  }
}

button.align{
    margin-top:25px !important;
}

.has-error-complementary{
  border: 2px solid red;
}

.has-error-monthly-cost{
  border: 2px solid red;
  color: red;
  border-radius: 0.25rem;
}

// Required field mark ======================================================================

.required-block {
  position: relative;
}

.required-block .required-icon,.required-block .required-icon-group{
  display: inline-block;
  vertical-align: middle;
  margin: -0.25em 0.25em 0em;
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  padding: 0.5em 0.8em;
  color: rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0.325em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
  font-size: 75%;
}

.required-block .required-icon {
  background-color: transparent;
  position: absolute;
  top: 2.5em;
  right: 0em;
  z-index: 10;
  margin: 0em;
  width: 30px;
  height: 30px;
  padding: 0em;
  text-align: center;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.required-block .required-icon-select {
  background-color: #E8E8E8;
  position: absolute;
  top: 1.94em;
  right: -1em;
  z-index: 10;
  margin: 0em;
  width: 15px;
  height: 35px;
  padding: 0em;
  text-align: center;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.required-block .required-icon-group {
  background-color: transparent;
  position: absolute;
  top: 0em;
  right: 0em;
  z-index: 10;
  margin: 0em;
  width: 30px;
  height: 30px;
  padding: 0em;
  text-align: center;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.required-block .required-icon:after, .required-block .required-icon-group:after {
  position: absolute;
  content: "";
  right: 0.05em;
  top: 0.05em;
  z-index: -1;
  width: 0em;
  height: 0em;
  border-top: 0em solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 0em solid transparent;
  border-right-color: inherit;
  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

.required-block .required-icon .text, .required-block .required-icon-group .text {
  color: #B80000;
  font-size: 20px;
  margin: -3px 0 0 12px;
}

.required-block .required-icon-select .text {
  color: #B80000;
  font-size: 20px;
  margin: 5px 0 0 0px;
}

.required-col {
  padding-right: 20px;
}

// Header Title ======================================================================

.header_title {
  background-color: #3498db;
  border-color: #3498db;
  color: #FFFFFF;
  border-radius: 0;
  padding: 1px 5px 1px 5px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#toasted-container{
  z-index: 999999 !important;
}

// Grid filter margin
.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 13px;
}

.img-center {margin:0 auto;}