//BASICS

//primary colors
$primary-color: rgba(1, 169, 223, 0.8);
$primary-color-dark: #2980b9;

$red-color: #e74c3c;
$red-color-dark: #c0392b;

$yellow-color: #f1c40f;
$yellow-color-dark: #f39c12;

$green-color: #2ecc71;
$green-color-dark: #27ae60;

$purple-color: #9b59b6;
$purple-color-dark: #8e44ad;

$gray-color: #95a5a6;
$gray-color-dark: #7f8c8d;
$gray-color-light: #c2c2c2;

//global text color
$text-color: #344644;

//global bg color
$main-bg-color: #e7ebee;

//global link color
$link-color: $primary-color;
$link-color-hover: $primary-color;


//TYPOGRAPHY

//font families
$font-stack: 'Open Sans', sans-serif;

//headings typo
$font-size-h1: 2em;
$font-size-h2: 1.8em;
$font-size-h3: 1.4em;
$font-size-h4: 1.2em;
$font-size-h5: 1em;
$font-size-h6: 0.875em;

$font-hx-weight: 300;

$line-height-base: 1.428571429 !default;

$heading-font-family: $font-stack;


//GRID - media queries breakpoints
$break-xxs-min: 420px;
$break-xs-min: 768px;
$break-sm-min: 992px;
$break-md-min: 1200px;

$break-xxs-max: ($break-xxs-min - 1);
$break-xs-max: ($break-xs-min - 1);
$break-sm-max: ($break-sm-min - 1);
$break-md-max: ($break-md-min - 1);

$container-max-width: 1920px;


//BOXED LAYOUT
$boxed-layout-max-width: 1200px;

$boxed-layout-breakdown2: 1260px;
$boxed-layout-max-width2: 1140px;

$boxed-layout-breakdown3: $break-md-max;
$boxed-layout-max-width3: 1024px;


//OTHER
$border-radius-base: 3px !default;
$border-radius-large: 6px !default;
$border-radius-small: 2px !default;
