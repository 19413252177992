
@import "widgets/conversation";
@import "widgets/users";
@import "widgets/infographic_box";
@import "widgets/recent_products";
@import "widgets/todo";
@import "widgets/graph_box";
@import "widgets/social_box";
@import "widgets/profile_box";
@import "widgets/profile_box_menu";
@import "widgets/profile_box_stats";
@import "widgets/profile_box_contact";